import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import { ContactForm, Layout, MapContainer, SEO, Wide } from '../components';
import markerIcon from '../images/map-marker-alt-solid.svg';
import phoneIcon from '../images/phone-solid.svg';

const ContactPage = ({ data }) => (
  <Layout subtitle="Contact">
    <SEO
      title="Contact"
      type="website"
      description="Get in contact with the team at D. Solis Properties. Leave a message, give us a call or visit our office in Fort Worth!"
      pathname="/contact/"
    />
    <Wide style={{ height: 150 }}>
      <Img
        fluid={data.banner.childImageSharp.fluid}
        style={{
          position: 'unset',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        imgStyle={{ flexShrink: 0, minWidth: '100%', minHeight: '100%' }}
        alt=""
      />
      <p
        style={{
          position: 'absolute',
          width: '90%',
          maxWidth: 960,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'right',
          color: 'white',
        }}
      >
        D. Solis Propertis/
        <span style={{ color: '#8ca36f' }}>{data.pageData.subtitle}</span>
      </p>
    </Wide>
    <h1
      style={{
        margin: '50px auto 30px auto',
        width: '90vw',
        maxWidth: 960,
        textAlign: 'center',
      }}
    >
      Contact Us
    </h1>
    <div
      style={{
        margin: '0 auto',
        height: 5,
        backgroundColor: 'rgb(140, 163, 111)',
        width: 50,
      }}
    />
    <ContactForm />
    <div style={{ margin: '40px auto 0 auto', width: '90vw', maxWidth: 960 }}>
      <h2
        style={{
          margin: '50px auto 30px auto',
          width: '90vw',
          maxWidth: 960,
          textAlign: 'center',
        }}
      >
        Call or Visit
      </h2>
      <div
        style={{
          margin: '0px auto',
          width: 50,
          height: 5,
          backgroundColor: 'rgb(140, 163, 111)',
        }}
      />
      <p style={{ marginTop: 20 }}>{data.pageData.message}</p>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            flexShrink: '0',
            minWidth: 300,
            marginLeft: 20,
          }}
        >
          <img src={markerIcon} alt="Adress Icon" style={{ height: 30 }} />
          <p style={{ marginLeft: 20 }}>
            {data.pageData.streetAddress}
            <br />
            {data.pageData.city}, {data.pageData.state} {data.pageData.zip}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            flexShrink: '0',
            minWidth: 300,
            marginLeft: 20,
          }}
        >
          <img src={phoneIcon} alt="Phone Icon" style={{ height: 30 }} />
          <p style={{ marginLeft: 20 }}>
            {data.pageData.phoneOne}
            <br />
            {data.pageData.phoneTwo}
          </p>
        </div>
      </div>
    </div>
    <Wide style={{ marginTop: 50, height: 350 }}>
      <MapContainer />
    </Wide>
  </Layout>
);

ContactPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default ContactPage;

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "fortworth.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pageData: pageJson(page: { eq: "contact" }) {
      subtitle
      message
      phoneOne
      phoneTwo
      streetAddress
      city
      state
      zip
    }
  }
`;
